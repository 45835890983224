import React, { createContext, useEffect, useMemo, useState } from "react";
import { userPool } from "../Utils/cognito-service";
import { navigate } from "gatsby";
import Cookies from "../components/Common/Cookies";

interface AppContextProps {
  isSize: string;
  Location: any;
  viewAll: any;
  setViewAll: any;
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppContextProvider: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [_width, setWidth] = useState(0);
  const [isSize, Setissize] = useState("");
  const [Location, setLocation] = useState<Object>({});
  const [cookiedata, setcookiedata] = useState<any>("true");
  const [viewAll, setViewAll] = useState<any>(false);
  const [errordata, Seterrordata] = useState<any>("");
  const location = window.location;
  let currentHostName = location.hostname;
  let hostNames = [
    "localhost",
    "staging.bahwancybertek.com",
    "test.bahwancybertek.com",
  ];

  const isUserLoggedIn = userPool.getCurrentUser();
  const handleResize = () => {
    setWidth(window.innerWidth);

    if (window.screen.width >= 1020) {
      Setissize("web");
    } else if (window.screen.width <= 1019 && window.screen.width >= 720) {
      Setissize("tab");
    } else if (window.screen.width <= 719) {
      Setissize("mobile");
    }
  };

  useEffect(() => {
    if (hostNames.includes(currentHostName)) {
      const user = userPool.getCurrentUser();
      if (!user) {
        navigate("/");
      }
    }

    const isLocationFetched = sessionStorage.getItem("location");
    if (isLocationFetched == null) {
      getGeoLocation();
    } else {
      setLocation(JSON.parse(isLocationFetched));
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.screen.width);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  useEffect(() => {
    handleResize();
    const cookiesvalue = getCookieValue();
    if (cookiesvalue || cookiesvalue != undefined || cookiesvalue != null) {
      setcookiedata(cookiesvalue);
    }
  }, []);

  const getGeoLocation = async () => {
    try {
      const response = await fetch("https://locs.bahwancybertek.biz/losc", {
        mode: "cors",
        headers: {
          "Content-type": "application/json",
        },
      });
      let result = await response.json();
      sessionStorage.setItem("location", JSON.stringify(result));
      setLocation(result);
    } catch (error) {
      Seterrordata(error);
    }
  };

  const getCookieValue = () => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName.trim() === "cookies") {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  return (
    <>
      {/* {hostNames.includes(currentHostName) == true ? (
        isUserLoggedIn != null ? (
          cookiedata === "true" || cookiedata === null ? (
            <Cookies />
          ) : (
            ""
          )
        ) : (
          ""
        )
      ) : cookiedata === "true" || cookiedata === null ? (
        <Cookies />
      ) : (
        ""
      )} */}
      {/* {(cookiedata === 'true' || cookiedata === null) && hostNames.includes(currentHostName) != true? <Cookies /> : ''} */}
      <AppContext.Provider
        value={useMemo(
          () => ({
            isSize,
            Location,
            viewAll,
            setViewAll,
          }),
          [isSize, Location, viewAll]
        )}
      >
        {children}
      </AppContext.Provider>
    </>
  );
};
